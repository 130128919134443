import React from 'react'
import ScrollCarousel from 'client/common/components/slider/ScrollCarousel'
import { getProductCards } from 'client/common/components/Products'
import { TranslateContext } from 'client/common/translator'
const { useContext } = require('react')
const classNames = require('classnames')

export function SoftContentProductsScroll({ collection, onProductClick, format, lang }) {
  const t = useContext(TranslateContext)
  const cardClassName = 'mxd-width-1-1 mxd-small-width-1-1'
  const cards = getProductCards({
    collection: collection.filter(c => !c.disableStatus?.isDisabled),
    t,
    lang,
    format,
    sizes: ['md'],
    cardClassName,
    onProductClick
  })

  return (
    <div
      className={classNames('mxd-cross-sell-block mxd-container mxd-container-gutterless', {
        'text-align-left': cards.length <= 4
      })}
    >
      <ScrollCarousel items={cards} options={{ showProgress: true, itemsAmount: cards.length }} />
    </div>
  )
}

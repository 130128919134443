import { Box } from 'chemistry'
import React from 'react'

export const FalseFloor = () => {
  return (
    <Box
      position='absolute'
      height='250px'
      width='100%'
      background='linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, white 45.83%)'
      opacity='0.8'
      bottom='0'
      z-index='1'
    />
  )
}

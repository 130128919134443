import React, { useReducer, useRef } from 'react'
import { TranslateContextProvider } from 'client/common/translator'
import { useAppearedInView } from 'client/common/hooks/useInview'
import { ChakraProvider } from '@chakra-ui/react'
import { Box, chemistryTheme, Container } from 'chemistry'
import SoftContentProductsHeader from './components/SoftContentProductsHeader/SoftContentProductsHeader'
import { UPDATE_PRODUCT_COLLECTION, productsReducer } from './store/reducer'
import { useXSellAnalytic } from '../../common/analytic/useXSellAnalytic'
import { SOFT_CONTENT_LIST_TYPE } from '../../common/analytic/analytic'
import { SoftContectProducts } from './components/SoftContectProducts/SoftContectProducts'
import { useFetchInitialSoftContentProducts } from './hooks/useFetchInitialSoftContentProducts'
import { fetchProductCollection } from './queries/fetchProductCollection'
import { SoftContentProductsNavigation } from './components/SoftContectProducts/SoftContentProductsNavigation'

const Component = ({
  productSelectionData,
  sameAsBefore,
  translations,
  backgroundColor,
  wrapper,
  sx,
  format,
  lang,
  ipAddress
}) => {
  const { loadMoreButtonRow, productsView, showTitleAsCard } = productSelectionData
  const crossSellRef = useRef()
  const inView = useAppearedInView(crossSellRef)

  const [{ productCollections, activeProductCollection, loading }, dispatchProducts] = useReducer(productsReducer, {
    productCollections: [],
    activeProductCollection: 0,
    loading: false
  })
  const currentProductCollection = productCollections[activeProductCollection]
  const { error } = useFetchInitialSoftContentProducts(
    { productSelectionData, dispatchProducts, ipAddress, format, lang },
    inView
  )

  const onProductClick = useXSellAnalytic({ products: currentProductCollection }, SOFT_CONTENT_LIST_TYPE)
  const loadNextPage = page => {
    fetchProductCollection({
      productCollectionData: currentProductCollection,
      productsView,
      page,
      loadMoreButtonRow,
      showTitleAsCard,
      ipAddress,
      format,
      lang
    }).then(productCollection => {
      dispatchProducts({ type: UPDATE_PRODUCT_COLLECTION, productCollection })
    })
  }
  const hideSelection = error || currentProductCollection?.collection?.length === 0
  if (hideSelection && sameAsBefore) return null

  return (
    <ChakraProvider resetCSS={false} theme={chemistryTheme}>
      <TranslateContextProvider translations={translations}>
        {hideSelection && !sameAsBefore ? (
          <Box data-product-selection-marker minHeight={{ base: '32px', md: '48px' }} />
        ) : (
          <Container
            data-product-selection-marker
            color={backgroundColor}
            sameAsBefore={sameAsBefore}
            wrapper={wrapper}
            sx={sx}
          >
            <div className='softContentProducts' ref={crossSellRef} style={{ minHeight: '1px' }}>
              {(!showTitleAsCard || productsView === 'carousel') && (
                <SoftContentProductsHeader {...productSelectionData} />
              )}
              {productCollections.length > 1 && (
                <SoftContentProductsNavigation
                  productCollections={productCollections}
                  currentProductCollectionId={currentProductCollection.id}
                  dispatchProducts={dispatchProducts}
                />
              )}
              <SoftContectProducts
                {...{
                  data: currentProductCollection,
                  error,
                  loading,
                  productSelectionData,
                  onProductClick,
                  loadNextPage,
                  format,
                  lang
                }}
              />
            </div>
          </Container>
        )}
      </TranslateContextProvider>
    </ChakraProvider>
  )
}

export default { Component }

import { getHeaders } from '../../../common/hooks/useCrossSellFetch'
import { getByIdProductsUrl, getCollectionProductsUrl } from '../../../common/xhr/gateway'
import axiosFetcher from '../helpers/axiosFetcherSoftContentProducts'
import { getCardsInRowAmount } from '../helpers/getCardsInRowAmount'

const MAX_AMOUNT_IN_CAROUSEL = 24
const MAX_AMOUNT_OF_ITEMS_IN_ROW = 4

const getItemsPerPage = ({ loadMoreButtonRow, showTitleAsCard, quantity }) => {
  const numberOfColumns = quantity ? getCardsInRowAmount(quantity, showTitleAsCard) : MAX_AMOUNT_OF_ITEMS_IN_ROW
  const numberOfRows = Number.isFinite(loadMoreButtonRow) ? loadMoreButtonRow : 3
  return numberOfRows * numberOfColumns
}

const sliceIdsForGrid = ({ ids, itemsPerPage, page, showTitleAsCard }) => {
  let from = itemsPerPage * (page - 1)
  let to = itemsPerPage * page
  if (showTitleAsCard) {
    if (page > 1) from -= 1
    to -= 1
  }
  return ids.slice(from, to)
}
const getSoftContentProductsUrl = ({
  productCollectionData: { currentVariant, collectionId, ids },
  productsView,
  page,
  loadMoreButtonRow,
  showTitleAsCard,
  format,
  lang
}) => {
  const itemsPerPage = getItemsPerPage({
    loadMoreButtonRow,
    showTitleAsCard,
    quantity: currentVariant === 'collectionId' ? undefined : ids?.length
  })
  if (currentVariant === 'collectionId') {
    return getCollectionProductsUrl({
      collectionId,
      lang,
      format,
      viewSize: productsView === 'grid' ? itemsPerPage : MAX_AMOUNT_IN_CAROUSEL,
      page
    })
  }

  return getByIdProductsUrl({
    format,
    lang,
    productCodes:
      productsView === 'grid'
        ? sliceIdsForGrid({ ids, itemsPerPage, page, showTitleAsCard })
        : ids.slice(0, MAX_AMOUNT_IN_CAROUSEL)
  })
}

export const fetchProductCollection = async ({
  productCollectionData: { currentVariant, name, id, collectionId, ids },
  productsView,
  page = 1,
  loadMoreButtonRow,
  showTitleAsCard,
  format,
  lang,
  ipAddress
}) => {
  const url = getSoftContentProductsUrl({
    productCollectionData: { currentVariant, collectionId, ids },
    productsView,
    page,
    loadMoreButtonRow,
    showTitleAsCard,
    format,
    lang
  })

  const result = await axiosFetcher.get(url, ipAddress ? getHeaders({ ipAddress }) : undefined)
  const { products: responseProducts, page: responsePage } = result || {}
  if (currentVariant === 'collectionId') {
    return { ...responseProducts, name, id, currentVariant, collectionId, page: responsePage }
  }
  return {
    ...responseProducts,
    currentVariant,
    name,
    id,
    ids,
    quantity: ids?.length,
    page
  }
}

import React from 'react'
import { RichText, Heading, Flex } from 'chemistry'

const SoftContentProductsHeader = ({ description, title, isCard }) => {
  return (
    <Flex flexDirection='column' gap={isCard ? '12px' : '16px'} marginBottom='32px'>
      <Heading as='h2' {...(isCard ? { fontSize: '28px' } : {})}>
        {title}
      </Heading>
      {description && <RichText json={description.json} />}
    </Flex>
  )
}

export default SoftContentProductsHeader

import React from 'react'
import { isLoading } from 'client/common/components/crossSell/List'
import ProductsPlaceholdersList from 'client/common/components/ProductsPlaceholdersList'
import { SoftContentProductsGrid } from './SoftContentProductsGrid'
import { SoftContentProductsScroll } from './SoftContentProductsScroll'

export const SoftContectProducts = ({
  data,
  error,
  loading,
  productSelectionData: { productsView, ...productSelectionData },
  onProductClick,
  loadNextPage,
  format,
  lang
}) => {
  const { collection, quantity, page, currentVariant } = data || {}
  if (error || !Array.isArray(collection) || collection.length === 0) return null
  if (isLoading(loading, collection)) return <ProductsPlaceholdersList count={5} perRow={5} />

  return productsView === 'grid' ? (
    <SoftContentProductsGrid
      collection={collection}
      currentVariant={currentVariant}
      quantity={quantity}
      productSelectionData={productSelectionData}
      page={page}
      loadNextPage={loadNextPage}
      format={format}
      lang={lang}
    />
  ) : (
    <SoftContentProductsScroll collection={collection} onProductClick={onProductClick} format={format} lang={lang} />
  )
}

import axios from 'axios'

const axiosFetcher = axios.create()
axiosFetcher.interceptors.response.use(({ data: axiosData }) => {
  if (axiosData.data && Object.keys(axiosData.data).length === 0) {
    axiosData.data = { products: { collection: [] } }
  }
  return axiosData
})

export default axiosFetcher

export const GET_PRODUCTS = 'GET_PRODUCTS'
export const SET_PRODUCT_COLLECTIONS = 'SET_PRODUCT_COLLECTIONS'
export const UPDATE_PRODUCT_COLLECTION = 'UPDATE_PRODUCT_COLLECTION'
export const SET_ACTIVE_PRODUCT_COLLECTION = 'SET_ACTIVE_PRODUCT_COLLECTION'

const setProductCollections = (state, { productCollections }) => {
  if (!Array.isArray(productCollections)) return state
  return {
    ...state,
    productCollections,
    loading: false
  }
}
const updateProductCollection = (state, { productCollection: newProductCollection }) => {
  if (!newProductCollection) return state

  const productCollections = [...state.productCollections]
  const index = productCollections.findIndex(({ id }) => id === newProductCollection.id)
  if (index < 0) {
    productCollections.push(newProductCollection)
    return
  }
  const { collection, quantity } = productCollections[index]
  productCollections[index] = {
    ...productCollections[index],
    collection: [...collection, ...newProductCollection.collection],
    quantity: newProductCollection.quantity || quantity,
    page: newProductCollection.page
  }

  return {
    ...state,
    productCollections,
    loading: false
  }
}

const setActiveProductCollection = (state, { activeProductCollection }) => ({ ...state, activeProductCollection })
export const productsReducer = (state, action) => {
  switch (action.type) {
    case GET_PRODUCTS:
      return { ...state, loading: true }
    case SET_PRODUCT_COLLECTIONS:
      return setProductCollections(state, action)
    case UPDATE_PRODUCT_COLLECTION:
      return updateProductCollection(state, action)
    case SET_ACTIVE_PRODUCT_COLLECTION:
      return setActiveProductCollection(state, action)
    default:
      return state
  }
}

import React from 'react'
import { Box, Button, Flex } from 'chemistry'
import { SET_ACTIVE_PRODUCT_COLLECTION } from '../../store/reducer'

export function SoftContentProductsNavigation({ productCollections, currentProductCollectionId, dispatchProducts }) {
  return (
    <Flex gap='0.75rem' paddingBottom='16px' marginBottom='32px' overflowX='auto'>
      {productCollections.map(({ name, id }, index) => (
        <Box key={id}>
          <Button
            sx={{
              ...(currentProductCollectionId === id
                ? {
                    color: 'red.500'
                  }
                : {})
            }}
            borderColor='grey.500'
            variant='secondary'
            textTransform='none'
            onClick={() => {
              dispatchProducts({ type: SET_ACTIVE_PRODUCT_COLLECTION, activeProductCollection: index })
            }}
          >
            {name}
          </Button>
        </Box>
      ))}
    </Flex>
  )
}

import { useEffect, useState } from 'react'
import { GET_PRODUCTS, SET_PRODUCT_COLLECTIONS } from '../store/reducer'
import { fetchProductCollection } from '../queries/fetchProductCollection'

export const useFetchInitialSoftContentProducts = (
  {
    productSelectionData: { productsView, products: productCollections, showTitleAsCard, loadMoreButtonRow = 3 },
    dispatchProducts,
    ipAddress,
    format,
    lang
  },
  inView
) => {
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!inView) return

    const setProducts = async () => {
      dispatchProducts({ type: GET_PRODUCTS })

      const nonEmptyProductCollections = productCollections.filter(
        ({ collectionId, ids, name } = {}) =>
          typeof name === 'string' &&
          name.length > 0 &&
          ((typeof collectionId === 'string' && collectionId.length > 0) || (Array.isArray(ids) && ids.length > 0))
      )
      let populatedProductCollections
      try {
        populatedProductCollections = await Promise.allSettled(
          nonEmptyProductCollections.map(productCollectionData =>
            fetchProductCollection({
              productCollectionData,
              productsView,
              page: 1,
              loadMoreButtonRow,
              showTitleAsCard,
              ipAddress,
              format,
              lang
            })
          )
        )
      } catch (error) {
        setError(error)
      }
      const results = []
      populatedProductCollections.forEach(({ status, value }) => {
        if (status === 'fulfilled') {
          results.push(value)
        }
      })

      dispatchProducts({
        type: SET_PRODUCT_COLLECTIONS,
        productCollections: results
      })
    }
    setProducts().catch(error => {
      setError(error)
    })
    // eslint-disable-next-line
  }, [inView])

  return { error }
}

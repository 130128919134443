import React, { useContext } from 'react'
import { TranslateContext } from '../../translator'
import {
  getProductListTranslations,
  getPromotionsTextsTranslations
} from '../../../fragments/listerFragment/selectors/translations'
import { propConverter } from 'chemistry'
import { Card } from '../Card/Card'

const ProductsGrid = ({
  sizes,
  format,
  lang,
  categoryCode,
  collection,
  searchResultType,
  onProductCardClick,
  cardTitle,
  target,
  cardsInRow = 4,
  withArrow,
  // needed for the frids with frontend-lib classes
  addClassName = true
}) => {
  const t = useContext(TranslateContext)
  return (
    <>
      {Boolean(cardTitle) && cardTitle}
      {collection.map(props => {
        const productListTranslations = getProductListTranslations(t)
        if (props.unitName) {
          const per = lang === 'fr' ? 'par' : 'per'
          const unitTranslated = t(`pim.units.${props.unitName}`)
          productListTranslations.units = `${per} ${unitTranslated}`
        }

        const cardProps = {
          ...props,
          format,
          lang,
          isDetail: false,
          t: productListTranslations,
          texts: getPromotionsTextsTranslations(t),
          showPraxisPlusBanner: false,
          translate: t
        }

        const fullPriceProps = propConverter(cardProps)
        return (
          <div
            key={`product-${props.ean}`}
            onClick={() => onProductCardClick(categoryCode, cardProps, searchResultType)}
            {...(addClassName ? { className: `mxd-width-1-${cardsInRow}` } : {})}
          >
            <Card
              {...cardProps}
              fullPriceProps={fullPriceProps}
              categoryCode={categoryCode}
              sizes={sizes}
              target={target}
              withArrow={withArrow}
            />
          </div>
        )
      })}
    </>
  )
}

export default ProductsGrid

import React, { useContext } from 'react'
import ProductsGrid from 'client/common/components/grid/ProductsGrid'
import { FalseFloor } from '../FalseFloor/FalseFloor'
import SoftContentProductsHeader from '../SoftContentProductsHeader/SoftContentProductsHeader'
import { Box, Flex, Grid, Link, MOBILE } from 'chemistry'
import { TranslateContext } from '../../../../common/translator'
import { getCardsInRowAmount } from '../../helpers/getCardsInRowAmount'

export function SoftContentProductsGrid({
  currentVariant,
  collection: originalCollection,
  quantity,
  productSelectionData: { showTitleAsCard, showFalseFloor, description, title },
  page = 1,
  loadNextPage,
  format,
  lang
}) {
  const t = useContext(TranslateContext)
  const cardsInRow = getCardsInRowAmount(quantity, showTitleAsCard)
  const isMore = originalCollection.length < quantity
  const nextPage = ((currentVariant === 'collectionId' ? page.page : page) || 1) + 1
  // it's not possible to manipulate the number of products in the fetching (no offset and limit) for collectionId
  const collection =
    currentVariant === 'collectionId' && isMore && showTitleAsCard
      ? originalCollection.slice(0, originalCollection.length - 1)
      : originalCollection
  return (
    <>
      <Box position='relative'>
        <Grid
          sx={{
            [MOBILE]: { gridTemplateColumns: '1fr' },
            gridTemplateColumns: `repeat(${cardsInRow}, 1fr)`
          }}
        >
          <ProductsGrid
            format={format}
            lang={lang}
            collection={collection}
            cardsInRow={cardsInRow}
            cardTitle={
              Boolean(showTitleAsCard) && (
                <SoftContentProductsHeader description={description} title={title} isCard={showTitleAsCard} />
              )
            }
            addClassName={false}
          />
        </Grid>
        {showFalseFloor && isMore && <FalseFloor />}
      </Box>
      <Flex justifyContent='center' width='100%' mt='md'>
        {isMore && (
          <Link
            variant='standard'
            as='button'
            onClick={() => {
              loadNextPage(nextPage)
            }}
          >
            {t('lister.soft_content.load_more')}
          </Link>
        )}
      </Flex>
    </>
  )
}
